/*
 * @Auth: linjituan
 * @Date: 2021-12-30 12:08:30
 * @LastEditors: linjituan
 * @LastEditTime: 2022-12-26 16:56:44
 */
export default {
  data() {
    return {
      cameraPositions: {
        // 0: { top: '1%', left: '1%' },
        // 1: { top: '1%', left: '79%' },
        // 2: { top: '70%', left: '1%' },
        // 3: { top: '70%', left: '79%' },
        // 4: { top: '1%', left: '22%' },
        // 5: { top: '1%', left: '58%' },
        // 6: { top: '52%', left: '22%' },
        // 7: { top: '52%', left: '58%' }
        0: { top: '29.5%', left: '80%' },
        1: { top: '53%', left: '80%' },
        2: { top: '76.5%', left: '80%' },
        3: { top: '1%', left: '60%' },
        4: { top: '29.5%', left: '60%' },
        5: { top: '53%', left: '60%' },
        6: { top: '76.5%', left: '60%' },
        7: { top: '76.5%', left: '40%' }
      },
      cameraTrackPositions: {
        0: { top: '1%', left: '80%' },
        1: { top: '25.5%', left: '80%' },
        2: { top: '50%', left: '80%' },
        3: { top: '74.5%', left: '80%' },
        4: { top: '1%', left: '59%' },
        5: { top: '25.5%', left: '59%' },
        6: { top: '50%', left: '59%' },
        7: { top: '74.5%', left: '59%' }
      },
      cameraHistoryPositions: {
        0: { top: '1%', left: '1%' },
        1: { top: '1%', left: '79%' },
        2: { top: '70%', left: '1%' },
        3: { top: '70%', left: '79%' },
        4: { top: '1%', left: '22%' },
        5: { top: '1%', left: '58%' },
        6: { top: '52%', left: '22%' },
        7: { top: '52%', left: '58%' }
      }
    }
  },
  computed: {
    secondaryCameras() {
      return this.cameras.filter((c) => c.mainCamera === 'no')
    }
  },
  methods: {
    setCameraPosition(index) {
      if (index >= this.cameraPositions.length) {
        return this.cameraPositions[1]
      } else {
        return this.cameraPositions[index]
      }
    },
    setTrackCameraPosition(index) {
      if (index >= this.cameraTrackPositions.length) {
        return this.cameraTrackPositions[1]
      } else {
        return this.cameraTrackPositions[index]
      }
    },
    setCameraHistoryPosition(index) {
      if (index >= this.cameraHistoryPositions.length) {
        return this.cameraHistoryPositions[1]
      } else {
        return this.cameraHistoryPositions[index]
      }
    },
    closeModalCameraChild(cameraId, StreamId) {
      console.log('closeModalCameraChild', arguments)
      console.log('closeModalCameraChild', this.cameras)
      this.cameras = this.cameras.map((c) => {
        if (c.id && c.id === cameraId) {
          this.$set(c, 'cameraVisible', false)
        } else if (c.streamId === StreamId) {
          this.$set(c, 'cameraVisible', false)
        }
        return c
      })
      if (StreamId) {
        this.$set(this.playBtnDisabled, StreamId, false)
        this.playingRowMap[StreamId] = {}
        if (this.historyObj[StreamId]) {
          for (const item of this.historyObj[StreamId]) {
            this.$set(item, 'playing', false)
          }
        }
      }
    },
    showCamera(cameraId) {
      console.log('showCamera,', cameraId)
      this.cameras = this.cameras.map((c) => {
        if (c.id === cameraId) {
          this.$set(c, 'cameraVisible', true)
        }
        return c
      })
    },
    showAllCamera() {
      this.cameras = this.cameras.map((c) => {
        this.$set(c, 'cameraVisible', true)
        return c
      })
    }
  }
}
