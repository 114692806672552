/*
 * @Auth: linjituan
 * @Date: 2021-12-20 17:08:16
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-09-05 16:40:49
 */
import request from '@/utils/request'

// 查询车辆能力摄像头扩展列表
export function listVehicleAbilityCamera(query) {
  return request({
    url: '/iot/vehicleAbilityCamera/list',
    method: 'get',
    params: query
  })
}

// 查询车辆能力摄像头扩展详细
export function getVehicleAbilityCamera(ID) {
  return request({
    url: '/iot/vehicleAbilityCamera/' + ID,
    method: 'get'
  })
}

// 新增车辆能力摄像头扩展
export function addVehicleAbilityCamera(data) {
  return request({
    url: '/iot/vehicleAbilityCamera',
    method: 'post',
    data: data
  })
}

// 修改车辆能力摄像头扩展
export function updateVehicleAbilityCamera(data) {
  return request({
    url: '/iot/vehicleAbilityCamera',
    method: 'put',
    data: data
  })
}

// 删除车辆能力摄像头扩展
export function delVehicleAbilityCamera(ID) {
  return request({
    url: '/iot/vehicleAbilityCamera/' + ID,
    method: 'delete'
  })
}

// 导出车辆能力摄像头扩展
export function exportVehicleAbilityCamera(query) {
  return request({
    url: '/iot/vehicleAbilityCamera/export',
    method: 'get',
    params: query
  })
}

// 摄像头历史视频
export function videoHistoryQuery(query) {
  return request({
    url: '/iot/vehicleAbilityCamera/videoHistoryQuery',
    method: 'get',
    params: query
  })
}

// 查询包含有指定视频流id在指定时间点前后20秒的视频流列表
export function searchEventVideoList(query) {
  return request({
    url: '/iot/vehicleAbilityCamera/searchEventVideoList',
    method: 'get',
    params: query
  })
}

// 解冻归档文件
export function unfreeze(query) {
  return request({
    url: '/iot/vehicleAbilityCamera/unfreeze',
    method: 'get',
    params: query
  })
}

// 检查指定阿里云OSS文件是否为归档文件
export function checkFileType(query) {
  return request({
    url: '/iot/vehicleAbilityCamera/checkFileType',
    method: 'get',
    params: query
  })
}

// 查询指定车辆在指定时间范围内的brtc历史视频记录
export function videoBrtcHistoryQuery(query) {
  return request({
    url: '/iot/vehicleAbilityCamera/videoBrtcHistoryQuery',
    method: 'get',
    params: query
  })
}
